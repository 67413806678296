import React, { useState } from 'react';

import { useAppDispatch } from '../../../../../../hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import GridSearchSelectWrapper from '../../../../../../components/common/formikWrappers/GridSearchSelectWrapper';
import { areLocationsEnabled } from '../../../../../../constants/AppConfig';
import { usePrivileges } from '../../../../../../state/auth/index.hooks';
import GridSearchDialog from '../../../../../../components/dialogs/GridSearchDialog';
import {
    useIsLocationsFetching,
    useLocationsRegister,
} from '../../../../../../pages/discovery/selectors/index.hooks';
import {
    ILocationEntity,
    ISourceSetEntity,
} from '../../../../../../state/types';
import {
    fetchLocationsRegister,
    resetLocationsRegister,
} from '../../../../../../state/_actions';

interface IOwnProps {
    errors?: string;
    location: string[];
    onChange: <T>(name: string, value: T) => void;
    subjectsKey: string;
}

const LocationField = ({
    errors,
    location,
    onChange,
    subjectsKey,
}: IOwnProps) => {
    const [isLocationsDialogOpen, setIsLocationsDialogOpen] = useState(false);
    const locationsRegister = useLocationsRegister();

    const isLocationsFetching = useIsLocationsFetching();
    const privileges = usePrivileges();
    const dispatch = useAppDispatch();
    const hasRightToLocations = areLocationsEnabled() && privileges.locations;

    const handleOpenLocations = () => {
        setIsLocationsDialogOpen(true);
    };

    const closeLocationsDialog = () => {
        setIsLocationsDialogOpen(false);
    };
    const searcherProps = {
        error: !!errors,
        helperText: errors,
        name: 'location',
        label: TranslationHelper.translate('Address'),
        type: 'text',
        required: true,
        InputProps: { readOnly: true },
        value: location || '',
        onClick: hasRightToLocations ? handleOpenLocations : undefined,
    };

    const confirmLocationsDialog = (data: ISourceSetEntity) => {
        closeLocationsDialog();
        const location = data as unknown as ILocationEntity;
        if (location.externalId) {
            onChange('location', data.name);
            onChange('objects', [{ [subjectsKey]: data.externalId }]);
        }
    };

    return (
        <>
            <GridSearchSelectWrapper {...searcherProps} />
            <GridSearchDialog
                sourceSet={locationsRegister}
                fetching={isLocationsFetching}
                isOpen={isLocationsDialogOpen}
                close={closeLocationsDialog}
                confirm={(data: ISourceSetEntity) =>
                    confirmLocationsDialog(data)
                }
                fetchDataHandler={(filter: string) => {
                    dispatch(
                        fetchLocationsRegister({
                            name: filter,
                            limit: 500,
                        })
                    );
                }}
                onResetData={() => dispatch(resetLocationsRegister())}
                title="Search location"
            />
        </>
    );
};

export default LocationField;
