import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import {
    restDeleteReport,
    restDeleteSchedule,
    restGetReports,
    restGetReportSchedules,
    restGetReportsDefinitions,
    restPostCreateSchedule,
    restPostGenerateReport,
    restPutUpdateSchedule,
} from '../../../services/reports';
import { TRootState } from '../../../store';
import { TStatus } from '../../types';

export interface IReport {
    id: string;
    status: number;
    type: {
        name: string;
    };
    subjects: { id: string; name: string }[];
    from: string;
    to: string;
    generatedAt: string;
    fileSize: number;
}

export interface IReports {
    total: number;
    start: number;
    values: IReport[];
}

export interface IFilter {
    start: number;
    limit: number;
}

export interface IReportsDefinition {
    typeId: number;
    name: string;
    templateId: number;
    isLocationReport: boolean;
}

export interface INewReport {
    type: number;
    template: string;
    from: string;
    to: string;
    subjects: string;
    language: string;
}

export interface IReportSchedule {
    id: string;
    type: {
        id: number;
        name: string;
    };
    repetition: {
        interval: string;
        startBoundaryDate: string;
        endBoundaryDate: string;
    };
    timeZone: {
        id: number;
        name: string;
    };
    subjects: { id: number; name: string }[];
    template: { id: number; name: string };
    language: string;
    emails?: string[];
}

export interface INewSchedule {
    type: number;
    repetition: {
        interval: string;
        startBoundaryDate: string;
        endBoundaryDate: string;
    };
    timeZone: number;
    subjects: number[];
    template: number;
    language: string;
    emails?: string[];
}

export interface IUpdateSchedule extends INewSchedule {
    id: string;
}

interface IState {
    tab: null | string;
    status: TStatus;
    mode: null | 'create' | 'edit';
    reports: IReports | null;
    reportsDefinitions: IReportsDefinition[] | null;
    reportsFilter: IFilter;
    schedules: IReportSchedule[] | null;
    selectedScheduleId: string | null;
    currentPage: number;
}

export const initialState: IState = {
    tab: 'reports',
    mode: null,
    reports: null,
    status: 'idle',
    reportsDefinitions: null,
    reportsFilter: {
        start: 0,
        limit: 25,
    },
    schedules: null,
    selectedScheduleId: null,
    currentPage: 1,
};

export const fetchReports = createAsyncThunk(
    'get:ui/reports/',
    async (params: IFilter = initialState.reportsFilter) => {
        const response = await restGetReports(params);
        return response;
    }
);
export const fetchReportsDefinitions = createAsyncThunk(
    'get:ui/reports/reportsDefinitions',
    async () => {
        const response = await restGetReportsDefinitions();
        return response;
    }
);

export const generateReport = createAsyncThunk(
    'post:ui/reports/',
    async (report: INewReport) => {
        const response = await restPostGenerateReport(report);
        return response;
    }
);

export const deleteReport = createAsyncThunk(
    'delete:ui/reports/',
    async (id: string) => {
        const response = await restDeleteReport(id);
        return response;
    }
);

export const fetchReportSchedules = createAsyncThunk(
    'get:ui/reports/schedules',
    async () => {
        const response = await restGetReportSchedules();
        return response;
    }
);

export const createSchedule = createAsyncThunk(
    'post:ui/reports/schedules',
    async (schedule: INewSchedule) => {
        const response = await restPostCreateSchedule(schedule);
        return response;
    }
);

export const updateSchedule = createAsyncThunk(
    'put:ui/reports/schedules',
    async ({ id, schedule }: { id: string; schedule: IUpdateSchedule }) => {
        const response = await restPutUpdateSchedule(id, schedule);
        return response;
    }
);

export const deleteSchedule = createAsyncThunk(
    'delete:ui/reports/schedules',
    async (id: string) => {
        const response = await restDeleteSchedule(id);
        return response;
    }
);
const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        enterCreateMode(state) {
            state.mode = 'create';
        },
        enterEditMode(state, action) {
            state.mode = 'edit';
            state.selectedScheduleId = action.payload;
        },
        resetMode(state) {
            state.mode = null;
        },
        selectTab(state, action) {
            state.tab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReports.fulfilled, (state, action) => {
            state.reports = action.payload;
        });
        builder.addCase(fetchReportSchedules.fulfilled, (state, action) => {
            state.schedules = action.payload;
        });
        builder.addCase(fetchReportsDefinitions.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchReportsDefinitions.fulfilled, (state, action) => {
            state.reportsDefinitions = action.payload.map((definition) => ({
                ...definition,
                isLocationReport: getIsLocationReport(definition.typeId),
            }));
            state.status = 'done';
        });
    },
});
const LOCATION_REPORT_IDS = [252, 253];
export const getIsLocationReport = (typeId: number) =>
    LOCATION_REPORT_IDS.includes(typeId);
export const getReportsMode = (state: TRootState) => state.ui.reports.mode;

export const reportsPaneVisible = createSelector(
    [getReportsMode],
    (mode) => !!mode
);

export const getCurrentPage = (state: TRootState) =>
    state.ui.reports.currentPage;
export const getTab = (state: TRootState) => state.ui.reports.tab;

export const getReports = (state: TRootState) => state.ui.reports.reports;

export const getReportsDefinitions = (state: TRootState) =>
    state.ui.reports.reportsDefinitions;

export const getSchedules = (state: TRootState) => state.ui.reports.schedules;
export const getReportDefinitionsStatus = (state: TRootState) =>
    state.ui.reports.status;

export const getSelectedScheduleId = (state: TRootState) =>
    state.ui.reports.selectedScheduleId;

export const getSelectedSchedule = createSelector(
    [getSelectedScheduleId, getSchedules],
    (id, schedules) =>
        schedules?.filter((schedule) => schedule.id === id)?.[0] || null
);
export const { enterCreateMode, enterEditMode, resetMode, selectTab } =
    reportsSlice.actions;

export default reportsSlice.reducer;
